﻿import { router } from "@/router/router";
import { useFrontendConfigStore } from "@/stores/frontend-config-store";
import { useTokenStore } from "@/stores/token-store";
import { createEventHook, createGlobalState } from "@vueuse/core";
import { ref } from "vue";

declare const google: any;

interface AuthResponse {
  user: User;
  jwt?: string;
  refreshToken?: string;
}

export interface User {
  email: string;
  userId: number;
  role: string;
  approverUserId: number;
  name: string;
  avatar: string;
  enabled: boolean;
}

export const useLoginStore = createGlobalState(() => new LoginStore());

class LoginStore {
  private tokenStore = useTokenStore();
  #configStore = useFrontendConfigStore();
  lastValidateResponse = ref<AuthResponse | { code: string, title: string } | undefined>(undefined);

  get isLoggedIn() {
    return this.tokenStore.isLoggedIn;
  }

  loggedIn = createEventHook<User>();

  async handleGoogleLogin(googleResponse: { credential: string }) {
    console.log("google login handler called");
    const response = await fetch(
      "/api/Login/validate-google-jwt?jwt=" + googleResponse.credential
    );
    const authResponse = await response.json() as AuthResponse;
    this.lastValidateResponse.value = authResponse;
    this.loggedIn.trigger(authResponse.user);
    if (!authResponse.jwt || !authResponse.refreshToken) return;
    this.tokenStore.currentJwt = authResponse.jwt;
    this.tokenStore.currentRefreshToken = authResponse.refreshToken;

    await this.#afterLogin();
  }

  async #afterLogin() {
    await router.push(this.tokenStore.nextLoginRedirect ? this.tokenStore.nextLoginRedirect : "/home");
    this.tokenStore.nextLoginRedirect = "";
  }

  async loginUsingToken(jwt: string) {
    this.tokenStore.currentJwt = jwt;
    this.tokenStore.currentRefreshToken = "";

    await this.#afterLogin();
  }

  private hasInitGoogle = false;

  async initGoogle() {
    if (this.hasInitGoogle) return;
    const [config] = await Promise.all([
      this.#configStore.config,
      this.#loadGoogleScript()
    ]);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    google.accounts.id.initialize({
      client_id: config.googleClientId,
      callback: this.handleGoogleLogin.bind(this),
      context: "signin",
      // auto_select: true,
      itp_support: true,
      cancel_on_tap_outside: false,
      prompt_parent_id: "g_popup"
    });
    this.hasInitGoogle = true;
  }

  #loadGoogleScript() {
    return new Promise<void>((resolve) => {
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        resolve();
      };
      document.body.appendChild(script);
    });
  }

  logout() {
    this.tokenStore.currentJwt = "";
    this.tokenStore.currentRefreshToken = "";
    void router.push("/login");
  }
}
