﻿import { createGlobalState } from "@vueuse/core";

export const useFrontendConfigStore = createGlobalState(() => new FrontendConfigStore());

interface FrontendConfig {
  googleClientId: string;
}

class FrontendConfigStore {
  #config: FrontendConfig | undefined = undefined;
  get config(): Promise<FrontendConfig> {
    if (this.#config) return Promise.resolve(this.#config);
    if (this.#pendingFetch) return this.#pendingFetch;
    return this.#pendingFetch = this.fetchConfig();
  }

  #pendingFetch: Promise<FrontendConfig> | undefined = undefined;

  private async fetchConfig() {
    const response = await fetch("/api/Config/get", { method: "GET" });
    const config: unknown = await response.json();
    if (!config) {
      throw new Error("unable to determine frontend config");
    }
    if (!this.isConfig(config)) {
      throw new Error("config returned is invalid");
    }
    this.#config = config;
    this.#pendingFetch = undefined;
    return config;
  }

  private isConfig(config: unknown): config is FrontendConfig {
    return config !== null 
      && typeof config === "object"
      && "googleClientId" in config;
  }
}