import { ref, watch } from "vue";

export type DoneFunc = {
  (): void,
  abort: () => void
};

export function useDialogHelper(
  onConfirm?: (done: DoneFunc) => void | Promise<void>,
  onCancel?: () => void,
  onOpen?: () => void
) {
  const shown = ref(false);
  let reason = "";
  const loading = ref(false);
  const primaryTitle = "confirm";
  watch(shown, (isShown) => {
    if (isShown) {
      reason = "";
      if (onOpen) onOpen();
      return;
    }
    if (reason !== primaryTitle && onCancel) {
      onCancel();
    }
    loading.value = false;
  });

  function cancel() {
    shown.value = false;
  }

  function confirm() {
    loading.value = true;
    reason = primaryTitle;
    if (!onConfirm) return done;
    try {
      const result = onConfirm(done);
      if (result) {
        result.catch(() => abort());
      }
    } catch {
      abort();
    }
    return done;
  }

  function abort() {
    loading.value = false;
    reason = "";
  }

  function done() {
    shown.value = false;
  }

  done.abort = abort;

  return { shown, cancel, confirm, loading };
}
