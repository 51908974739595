﻿import { graphql } from "@/gql";
import type { CostCentersQuery, MyCostCentersSubscription } from "@/gql/graphql";
import { useTokenStore } from "@/stores/token-store";
import type { MseagUser } from "@/stores/user-store";
import { useUserStore } from "@/stores/user-store";
import { useLazyQuery, useMutation, useSubscription } from "@vue/apollo-composable";
import { resolveUnref, until } from "@vueuse/core";
import type { MaybeComputedRef } from "@vueuse/shared";
import { computed, type Ref } from "vue";

export type CostCenter = CostCentersQuery["mseagFinanceCostCenters"][number];
export type EditableCostCenter = Pick<CostCenter, "description" | "code" | "id">;
export type CostAccount = CostCenter["accounts"][number];
export const useCostCenterStore = () => new CostCenterStore();

class CostCenterStore {
  //language=graphql
  #costCentersQuery = useLazyQuery(
    graphql(`
        query costCenters {
            mseagFinanceCostCenters(orderBy: {code: ASC}) {
                id
                code
                description
                accounts(orderBy: {code: ASC}) {
                    id
                    code
                    description
                    costCenterId
                }
            }
        }
    `)
  );

  get costCenters(): CostCenter[] {
    this.#costCentersQuery.load();
    return this.#costCentersQuery.result.value?.mseagFinanceCostCenters ?? [];
  }
  
  get costCentersLoading() {
    return this.#costCentersQuery.loading.value;
  }

  async loadCostCenters() {
    this.#costCentersQuery.load();
    await until(this.#costCentersQuery.loading).toBe(false);
  }

  #tokenStore = useTokenStore();

  userCostCenters(userId: MaybeComputedRef<number>) {
    //language=graphql
    const subscription = useSubscription(
      graphql(`
          subscription myCostCenters($userId: Int!) {
              mseagFinanceCostCenters(orderBy: {code: ASC}) {
                  id
                  code
                  description
                  accounts(
                      orderBy: {code: ASC},
                      where: {user_accounts: {userId: {_eq: $userId}}}
                  ) {
                      id
                      code
                      description
                      costCenterId
                  }
              }
          }
      `),
      () => ({
        userId: resolveUnref(userId)
      })
    );
    const myCostCenters = computed(() => {
      return subscription.result.value?.mseagFinanceCostCenters.filter(cc => cc.accounts.length > 0) ?? [];
    }) as Ref<MyCostCentersSubscription["mseagFinanceCostCenters"]>;
    return {
      costCenters: myCostCenters,
      loading: subscription.loading
    };
  }

  //language=graphql
  #costCenterMutation = useMutation(
    graphql(`
        mutation updateCostCenter($id: Int!, $code: String!, $description: String!) {
            updateMseagFinanceCostCentersByPk(
                pk_columns: {id: $id}
                _set: {code: $code, description: $description}
            ) {
                id
                code
                description
            }
        }
    `)
  );

  async updateCostCenter(costCenter: EditableCostCenter) {
    await this.#costCenterMutation.mutate({
      id: costCenter.id,
      code: costCenter.code,
      description: costCenter.description
    });
  }

  //language=graphql
  #insertCostCenterMutation = useMutation(
    graphql(`
        mutation insertCostCenter($costCenter: MseagFinanceCostCentersInsertInput!) {
            insertMseagFinanceCostCentersOne(
                object: $costCenter
            ) {
                id
                code
                description
            }
        }
    `)
  );

  async insertCostCenter(costCenter: EditableCostCenter): Promise<number | null> {
    const [newCostCenter] = await Promise.all([
      this.#insertCostCenterMutation.mutate({
        costCenter: {
          description: costCenter.description,
          code: costCenter.code,
          accounts: 
            {
              data: [
                {code: '11690', description: 'Cash Advance USD'},
                {code: '64150', description: 'Travel'},
                {code: '64400', description: 'Tech / Computer'},
                {code: '64550', description: 'Bank Fees'},
                {code: '63740', description: 'Communications'},
                {code: '63600', description: 'Supplies'},
              ]
            }
        }
      }),
      this.#costCentersQuery.refetch()
    ]);
    return newCostCenter?.data?.insertMseagFinanceCostCentersOne?.id ?? null;
  }

  //language=graphql
  #accountCostCenterMutation = useMutation(
    graphql(`
        mutation updateAccountCostCenter($id: Int!, $code: String!, $description: String!) {
            updateMseagFinanceAccountsByPk(
                pk_columns: {id: $id}
                _set: {code: $code, description: $description}
            ) {
                id
                code
                description
            }
        }
    `)
  );

  async updateCostCenterAccount(account: CostAccount) {
    await this.#accountCostCenterMutation.mutate({
      id: account.id,
      code: account.code,
      description: account.description
    });
  }

  //language=graphql
  #insertAccountCostCenterMutation = useMutation(
    graphql(`
        mutation insertAccountCostCenter($code: String!, $description: String!, $costCenterId: Int!) {
            insertMseagFinanceAccountsOne(
                object: {code: $code, description: $description, costCenterId: $costCenterId}
            ) {
                id
                code
                description
                cost_center {
                    id
                    accounts(orderBy: {code: ASC}) { id }
                }
            }
        }
    `)
  );

  async insertCostAccount(account: CostAccount) {
    await this.#insertAccountCostCenterMutation.mutate({
      code: account.code,
      description: account.description,
      costCenterId: account.costCenterId
    });
  }

  //language=graphql
  #mutateUserAccounts = useMutation(
    graphql(`
        mutation syncUserAccounts($userAccountsToDelete: [Int!]!, $newAccounts: [MseagFinanceUserAccountsInsertInput!]!) {
            deleteMseagFinanceUserAccounts(
                where: {id: {_in: $userAccountsToDelete}}
            ) {
                affected_rows
            }
            insertMseagFinanceUserAccounts(
                objects: $newAccounts
            ) {
                affected_rows
            }
        }
    `)
  );

  #userStore = useUserStore();

  async syncUserAccounts(user: MseagUser, accounts: Set<number>) {
    const accountsToAdd = [];
    const userAccountsToRemove = [];
    for (const userAccount of user.user_accounts) {
      if (accounts.delete(userAccount.account.id)) {
        //don't do anything, the account should stay
        continue;
      }
      //account not found so it should be removed
      userAccountsToRemove.push(userAccount.id);
    }
    //any accounts left don't exist in the user already so we should add them
    accountsToAdd.push(...accounts);
    await Promise.all([
      this.#mutateUserAccounts.mutate({
        userAccountsToDelete: userAccountsToRemove,
        newAccounts: accountsToAdd.map(accountId => ({ userId: user.id, accountId }))
      }),
      this.#costCentersQuery.refetch(),
      this.#userStore.refetchUsers()
    ]);

  }

  //language=graphql
  #deleteCostAccountMutation = useMutation(
    graphql(`
        mutation deleteCostAccount($id: Int!) {
            deleteMseagFinanceAccountsByPk(id: $id) {
                cost_center {
                    id
                    accounts(orderBy: {code: ASC}) { id }
                }
            }
        }`)
  );

  async deleteCostAccount(account: CostAccount) {
    await this.#deleteCostAccountMutation.mutate({ id: account.id });
  }

  //language=graphql
  #deleteCostCenterMutation = useMutation(
    graphql(`
        mutation deleteCostCenter($id: Int!) {
            deleteMseagFinanceCostCentersByPk(id: $id) {
                id
            }
        }`)
  );

  async deleteCostCenter(costCenter: CostCenter) {
    await Promise.all([
      this.#deleteCostCenterMutation.mutate({ id: costCenter.id }),
      this.#costCentersQuery.refetch()
    ]);
  }
}