﻿<template>
  <template v-if="loading">
    <v-progress-circular class="ma-4" indeterminate />
  </template>
  <template v-else>
    <MyDraftAdvances v-if="advances.length > 0" :advances="advances" />
    <MyPendingApprovals v-if="approvals.length > 0" :approvals="approvals" />
    <MyPendingConfirmations v-if="confirmations.length > 0" :confirmations="confirmations" />
    <MyPendingReportApprovals v-if="pendingReportStore.pendingReports.length > 0" :pending-reports="pendingReportStore.pendingReports" />
  </template>
  <PendingPayments v-if="$isAdmin" />
</template>

<script setup lang="ts">
import MyDraftAdvances from "@/components/Actions/MyDraftAdvances.vue";
import MyPendingConfirmations from "@/components/Actions/MyPendingConfirmations.vue";
import MyPendingReportApprovals from "@/components/Actions/MyPendingReportApprovals.vue";
import MyPendingApprovals from "@/components/Actions/MyPendingApprovals.vue";
import PendingPayments from "@/components/Actions/PendingPayments.vue";
import { MseagFinanceExpenseReportLineItemStatusEnum } from "@/gql/graphql";
import { useCashApprovalStore } from "@/stores/cash-actions-store";
import { usePendingReportStore } from "@/stores/pending-report-store";
import { computed } from "vue";

const pendingReportStore = usePendingReportStore();
const cashApprovalStore = useCashApprovalStore();
const { loading } = cashApprovalStore.loadMyLineItemActions();
const approvals = computed(() => {
  return cashApprovalStore.actions.filter(li => li.status === MseagFinanceExpenseReportLineItemStatusEnum.Pending);
});
const confirmations = computed(() => {
  return cashApprovalStore.actions.filter(li => li.status === MseagFinanceExpenseReportLineItemStatusEnum.Paid);
});
const advances = computed(() => {
  return cashApprovalStore.actions.filter(li => li.status === MseagFinanceExpenseReportLineItemStatusEnum.Draft);
});
</script>

<style scoped>

</style>