﻿import {
  MseagFinanceExpenseReportLineItemStatusEnum,
  MseagFinanceExpenseReportLineItemTypeEnum,
  MseagFinanceExpenseReportResolutionStrategyEnum, MseagFinanceExpenseReportStatusEnum
} from "@/gql/graphql";
import { currencyNameMap } from "@/services/currencies";
import { useAppFetch } from "@/services/http-service";
import type { Report, LineItem } from "@/stores/report-detail-store";
import type { ExpenseReportStrategy, SubmitReportPayload } from "@/stores/submit-expense-report-store";
import { mdiFile, mdiFileCheck, mdiFileLock, mdiFileQuestion, mdiHelp } from "@mdi/js";

interface LineItemToCalculateBalance {
  amount: number;
  exchangeRate: number;
}

interface FileId {
  value: string;
}

class ExpenseReportService {
  calculateBalance(report: { line_items: LineItemToCalculateBalance[] } | undefined | null): number {
    let sum = 0;
    if (!report) return 0;
    for (const lineItem of report.line_items) {
      sum += lineItem.amount;
    }
    return sum;
  }
  
  calculateApprovalResultLineItem(report: Report): LineItem|undefined {
    if (report.status !== MseagFinanceExpenseReportStatusEnum.Pending) return undefined;
    switch (report.resolutionStrategy) {
      case MseagFinanceExpenseReportResolutionStrategyEnum.Resolve:
        return;
      case MseagFinanceExpenseReportResolutionStrategyEnum.Repay:
        return {
          id: -1,
          type: MseagFinanceExpenseReportLineItemTypeEnum.Payback,
          status: MseagFinanceExpenseReportLineItemStatusEnum.Pending,
          created_by_user: report.user,
          amount: this.calculateBalance(report) * -1,
          purpose: 'Payback',
          createdAt: report.updatedAt,
          account: undefined,
          accountId: undefined,
          approvedAt: undefined,
          channel: undefined,
          createdByUserId: report.user.id,
          currency: report.currency,
          exchangeRate: 1,
          reportId: 0,
          updatedAt: ''
        };
      case MseagFinanceExpenseReportResolutionStrategyEnum.Reimbursement:
        return {
          id: -1,
          type: MseagFinanceExpenseReportLineItemTypeEnum.Reimbursement,
          status: MseagFinanceExpenseReportLineItemStatusEnum.Pending,
          created_by_user: report.user,
          amount: this.calculateBalance(report) * -1,
          purpose: 'Reimbursement',
          createdAt: report.updatedAt,
          account: undefined,
          accountId: undefined,
          approvedAt: undefined,
          channel: undefined,
          createdByUserId: report.user.id,
          currency: report.currency,
          exchangeRate: 1,
          reportId: 0,
          updatedAt: ''
        };
      case MseagFinanceExpenseReportResolutionStrategyEnum.Rollover:
        return {
          id: -1,
          type: MseagFinanceExpenseReportLineItemTypeEnum.Rollover,
          status: MseagFinanceExpenseReportLineItemStatusEnum.Pending,
          created_by_user: report.user,
          amount: this.calculateBalance(report) * -1,
          purpose: 'Rollover',
          createdAt: report.updatedAt,
          account: undefined,
          accountId: undefined,
          approvedAt: undefined,
          channel: undefined,
          createdByUserId: report.user.id,
          currency: report.currency,
          exchangeRate: 1,
          reportId: 0,
          updatedAt: ''
        };
    }
  }

  defaultReportTitle(currency: string | undefined) {
    currency = currencyNameMap[currency ?? ''] ?? '';
    const date = new Date();
    const dateString = date.toLocaleString("default", { month: "long", day: "numeric", year: "numeric" });
    return `${dateString}${currency ? ' ' : ''}${currency??''} Expense Report`;
  }

  async markPaid(advanceId: number, datePaid: Date) {
    const { execute } = useAppFetch(
      `/api/Approval/mark-paid?id=${advanceId}&datePaid=${datePaid.toISOString()}`,
      { method: "post" },
      { immediate: false }
    );
    await execute();
  }

  async confirmPaid(advanceId: number) {
    const { execute } = useAppFetch(
      `/api/Approval/confirm-paid?id=${advanceId}`,
      { method: "post" },
      { immediate: false }
    );
    await execute();
  }

  async approveCashAdvance(cashAdvanceId: number, body: { note: string }) {
    const { execute } = useAppFetch(
      `/api/Approval/cash-advance?id=${cashAdvanceId}`,
      { method: "post", body: JSON.stringify(body), headers: { "Content-Type": "application/json" } },
      { immediate: false }
    );
    await execute();
  }

  async submitCashAdvance(id: number) {
    const { execute } = useAppFetch(
      `/api/Approval/submit-advance?id=${id}`,
      { method: "post" },
      { immediate: false }
    );
    await execute(true);
  }

  async approveReport(reportId: number) {
    const { data, error, execute } = useAppFetch(
      `/api/expenseReport/approveReport?id=${reportId}`,
      { method: "post" },
      { immediate: false }
    );
    await execute(true);
  }
  
  async rejectReport(reportId: number, reason: string) {
    const { execute } = useAppFetch(
      `/api/expenseReport/rejectReport?id=${reportId}&reason=${reason}`,
      { method: "post" },
      { immediate: false }
    );
    await execute(true);
  }

  async submitReport(reportId: number, strategy: ExpenseReportStrategy, payload: SubmitReportPayload) {
    const { data, error, execute } = useAppFetch(
      `/api/expenseReport/submit?id=${reportId}`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...payload, strategy: strategy })
      },
      { immediate: false }
    );
    await execute(true);
  }

  newImageId(): FileId {
    return {value: crypto.randomUUID().replaceAll("-", "")};
  }

  imagePath(reportId: number, fileId: FileId, file: File) {
    return `/UserImages/${reportId}/${fileId.value}/${file.name}`;
  }

  async uploadReceipt({ file, reportId, fileId, lineItemId }: { file: File, reportId: number, fileId: FileId, lineItemId: number }) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const { execute } = useAppFetch(
      `/api/receipt/upload-image?reportId=${reportId}&fileId=${fileId.value}&lineItemId=${lineItemId}`,
      {
        method: "post",
        body: formData
      }, {immediate: false}
    );
    await execute(true);
  }


  async deleteImage(id: number) {
    await useAppFetch(`/api/Receipt/deleteReceipt?id=${id}`, {method: 'delete'});
  }
  
  async deleteReport(reportId: number) {
    const { execute } = useAppFetch(
      `/api/expenseReport/deleteReport?reportId=${reportId}`,
      {
        method: "post",
      }, { immediate: false }
    );
    await execute(true);
  }
  
  async approveLineItem(lineItemId: number) {
    const { execute } = useAppFetch(
      `/api/expenseReport/approveLineItem?lineItemId=${lineItemId}`,
      {
        method: "post",
      }, { immediate: false }
    );
    await execute(true);
  }
  
  async rejectLineItem(lineItemId: number) {
    const { execute } = useAppFetch(
      `/api/expenseReport/rejectLineItem?lineItemId=${lineItemId}`,
      {
        method: "post",
      }, { immediate: false }
    );
    await execute(true);
  }

  getIcon(report: { status: MseagFinanceExpenseReportStatusEnum }) {
    switch (report.status) {
      case MseagFinanceExpenseReportStatusEnum.Draft:
        return mdiFile;
      case MseagFinanceExpenseReportStatusEnum.Pending:
        return mdiFileQuestion;
      case MseagFinanceExpenseReportStatusEnum.Approved:
        return mdiFileCheck;
      case MseagFinanceExpenseReportStatusEnum.Closed:
        return mdiFileLock;
    }
    return mdiHelp;
  }
  
  calculateProgress(report: {status: MseagFinanceExpenseReportStatusEnum}) {
    switch (report.status) {
      case MseagFinanceExpenseReportStatusEnum.Draft:
        return 25;
      case MseagFinanceExpenseReportStatusEnum.Pending:
        return 50;
      case MseagFinanceExpenseReportStatusEnum.Approved:
        return 75;
      case MseagFinanceExpenseReportStatusEnum.Closed:
        return 100;
      default:
        return 0;
    }
  }
}

export const expenseReportService = new ExpenseReportService();
