﻿<template>
  <ApprovalDialog title="Mark Paid" :report-id="payment.reportId" max-width="40rem" @confirm="confirm">
    <p>Would you like to Mark this this {{ $expenseTypeName(payment.type) }} Paid?</p>
    <p>
      Purpose: {{ payment.purpose }}
    </p>
    <p v-if="payment.note">
      Approval note: {{ payment.note }}
    </p>
    <p>
      Amount: 
      <Currency :currency="payment.currency" /> <Number :value="payment.amount" />
    </p>
    <p>
      <v-chip class="mr-2" :prepend-avatar="payment.created_by_user?.avatar ?? undefined">
        Created By: {{ payment.created_by_user?.name ?? 'Unknown' }}
      </v-chip>
      <v-chip :prepend-avatar="payment.approved_by_user?.avatar ?? undefined">
        Approved By: {{ payment.approved_by_user?.name ?? 'Unknown' }}
      </v-chip>
    </p>
    <ChannelPaymentDetails v-if="payment.channel" :channel="payment.channel" :first-use="payment.channel.expenseReportLineItemsAggregate.aggregate?.count === 0" />
    <p v-else>
      No Payment Channel found, this may be an error
    </p>
    <MyDatePicker v-model="datePaid" class="mt-2" label="Date Paid" hide-details />
  </ApprovalDialog>
</template>
<script setup lang="ts">
import ApprovalDialog from "@/components/Actions/ApprovalDialog.vue";
import ChannelPaymentDetails from "@/components/Channel/ChannelPaymentDetails.vue";
import Currency from "@/components/Currency.vue";
import MyDatePicker from "@/components/MyDatePicker.vue";
import Number from "@/components/Number.vue";
import type { DoneFunc } from "@/composables/useDialogHelper";
import type { PendingPayment } from "@/stores/cash-payment-store";
import { ref } from "vue";

const props = defineProps<{ payment: PendingPayment, onPaid: (date: Date) => Promise<any> }>();
const datePaid = ref(new Date());

async function confirm(done: DoneFunc) {
  try {
    await props.onPaid(datePaid.value);
  } catch {
    done.abort();
    return;
  }
  done();
}
</script>

<style scoped>

</style>