﻿import { createLoginState } from "@/composables/createLoginState";
import { graphql } from "@/gql";
import type { LoadPendingPaymentsSubscription } from "@/gql/graphql";
import { expenseReportService } from "@/services/expense-report-service";
import { useTokenStore } from "@/stores/token-store";
import { useLazyQuery, useSubscription } from "@vue/apollo-composable";

export type PendingPayment = LoadPendingPaymentsSubscription["mseagFinanceExpenseReportLineItem"][number];
export type ChannelDetails = NonNullable<PendingPayment["channel"]>;
export const useCashPaymentStore = () => new CashPaymentStore(); 
class CashPaymentStore {
  #tokenStore = useTokenStore();
  //language=GraphQL
  #pendingPaymentsQuery = useSubscription(
    graphql(`
        subscription loadPendingPayments($userId: Int!) {
            mseagFinanceExpenseReportLineItem(
                where: {
                    type: { _in: [CASH, REIMBURSEMENT] }
                    status: { _eq: APPROVED }
                    channel: {channel_sub_type: {channel_contacts: {userId: {_eq: $userId}}}}
                },
                orderBy: {createdAt: ASC}
            ) {
                id
                createdAt
                status
                type
                amount
                currency
                purpose
                reportId
                note
                created_by_user {
                    id
                    avatar
                    name
                }
                approved_by_user {
                    id
                    avatar
                    name
                }
                channel {
                    id
                    name
                    config
                    channel_sub_type {
                        id
                        type
                        name
                    }
#                    count used for determining if this is the first time the channel is being used
                    expenseReportLineItemsAggregate(where: {status: {_in: [PAID, CONFIRMED]}}) {
                        aggregate {
                            count
                        }
                    }
                }
                
            }
        }
    `),
    {userId: this.#tokenStore.userId ?? -1}
  );
  
  get pendingPayments(): PendingPayment[] {
    return this.#pendingPaymentsQuery.result.value?.mseagFinanceExpenseReportLineItem ?? [];
  }

  async markPaid(advanceId: number, datePaid: Date) {
    await expenseReportService.markPaid(advanceId, datePaid);
  }
}
