﻿<template>
  <v-app>
    <v-app-bar app color="primary" extension-height="4">
      <v-btn v-show="loginStore.isLoggedIn" class="d-print-none" variant="text" :icon="mdiMenu" @click="drawer = !drawer" />
      <v-toolbar-title>{{ title ?? "MSEAG Finance" }}</v-toolbar-title>
      <v-spacer class="d-none d-md-block" />
      <span class="d-none d-md-block">{{ tokenStore.userEmail }}</span>
      <v-btn v-show="loginStore.isLoggedIn" id="overflow-button" variant="text" :icon="mdiDotsVertical" />
      <template #extension>
        <v-progress-linear :hidden="!loadingRoute" indeterminate />
      </template>
    </v-app-bar>

    <v-main>
      <v-navigation-drawer v-model="drawer" disable-resize-watcher>
        <v-list>
          <v-list-item title="Home" :prepend-icon="mdiHome" to="/home" />
          <v-list-item title="Reports" :prepend-icon="mdiReceiptText" to="/reports" />
          <v-list-item title="Payment Channels" :prepend-icon="mdiBankTransfer" to="/channels" />
          <v-list-item title="Cost Centers" :prepend-icon="mdiBank" to="/costCenters" />
          <v-list-item title="Users" :prepend-icon="mdiAccountMultiple" to="/users" />
          <v-list-item title="Export" :prepend-icon="mdiFileExport" to="/export" />
          <v-list-item v-if="$isDev" title="Testing" :prepend-icon="mdiUnicorn" to="/testing" />
        </v-list>
      </v-navigation-drawer>
      <router-view />
    </v-main>
  </v-app>
  <v-menu activator="#overflow-button">
    <v-list select-strategy="classic">
      <v-list-item title="Log out" :prepend-icon="mdiLogout" @click="loginStore.logout()" />
      <v-list-item density="compact" :title="`Version: ${AppVersionNumber}`" />
    </v-list>
  </v-menu>
</template>
<script setup lang="ts">
import { useLoginStore } from "@/stores/login-store";
import { useTokenStore } from "@/stores/token-store";
import {
  mdiAccountMultiple,
  mdiBank,
  mdiBankTransfer,
  mdiDotsVertical,
  mdiFileExport,
  mdiHome,
  mdiLogout,
  mdiMenu,
  mdiReceiptText, mdiUnicorn
} from "@mdi/js";
import { useTitle } from "@vueuse/core";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { AppVersionNumber } from "../version";

const router = useRouter();
const loginStore = useLoginStore();
const tokenStore = useTokenStore();
const pageTitle = useTitle(undefined, { observe: true });
const loadingRoute = ref(false);
const title = computed(() => {
  if (pageTitle.value == "MSEAG Finance") {
    return pageTitle.value;
  }
  return pageTitle.value?.slice(0, -"| MSEAG Finance".length);
});
router.beforeEach((to, from, next) => {
  loadingRoute.value = true;
  next();
});
router.afterEach((to, from, failure) => {
  loadingRoute.value = false;
  if (!failure) {
    drawer.value = false;
  }
});
const drawer = ref(false);
</script>

<style scoped>

</style>