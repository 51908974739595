﻿<template>
  <h5 class="ml-2">
    Draft Cash Request
  </h5>
  <v-divider />
  <v-list>
    <v-list-item v-for="advance of advances" :key="advance.id" :prepend-icon="mdiFileQuestion" class="show-child-on-hover">
      <v-list-item-title>
        <LineItemId :id="advance.id" size="small" />
        {{ advance.purpose }}
        <v-chip v-if="advance.channel" :prepend-icon="mdiBankTransfer">
          {{ advance.channel.name }}
        </v-chip>
      </v-list-item-title>
      <v-list-item-subtitle>
        <Currency :currency="advance.currency" /> <Number :value="advance.amount" />
      </v-list-item-subtitle>
      <template #append>
        <v-btn variant="tonal" color="green" icon class="show-on-hover">
          <v-icon :icon="mdiCheck" color="green" />
          <ApprovalDialog title="Confirm" :report-id="advance.reportId" @confirm="(done) => submitAdvance(advance.id, done)">
            <p>Submit this Cash Request for approval?</p>
            <p>
              Purpose: {{ advance.purpose }}
            </p>
            <p>
              Amount:
              <Currency :currency="advance.currency" /> <Number :value="advance.amount" />
            </p>
          </ApprovalDialog>
        </v-btn>
      </template>
    </v-list-item>
  </v-list>
</template>

<script setup lang="ts">
import ApprovalDialog from "@/components/Actions/ApprovalDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Currency from "@/components/Currency.vue";
import LineItemId from "@/components/LineItems/LineItemId.vue";
import Number from "@/components/Number.vue";
import type { DoneFunc } from "@/composables/useDialogHelper";
import { type ActionLineItem, useCashApprovalStore } from "@/stores/cash-actions-store";
import { mdiBankTransfer, mdiCheck, mdiFileQuestion } from "@mdi/js";

const props = defineProps<{ advances: ActionLineItem[] }>();
const cashApprovalStore = useCashApprovalStore();
async function submitAdvance(id: number, done: DoneFunc) {
  await cashApprovalStore.submitCashAdvance(id);
  done();
}
</script>

<style scoped>

</style>