﻿import { useErrorStore } from "@/stores/error-store";
import { type TokenStore, useTokenStore } from "@/stores/token-store";
import { type BeforeFetchContext, createFetch, type UseFetchOptions, type UseFetchReturn } from "@vueuse/core";
import type { Router } from "vue-router";
import { router } from "@/router/router";

class HttpService {

  constructor(private router: Router) {
  }

  #refreshPromise: Promise<void> | undefined;

  async refreshJwt(): Promise<void> {
    if (this.#refreshPromise) return this.#refreshPromise;
    this.#refreshPromise = this.refreshJwtInternal().finally(() => this.#refreshPromise = undefined);
    return this.#refreshPromise;
  }

  private async refreshJwtInternal() {
    const tokenStore = useTokenStore();
    console.log("token expired, refreshing token");
    if (!tokenStore.currentRefreshToken) {
      await this.#redirectToLogin(this.router, tokenStore);
      return;
    }
    const response = await fetch(
      `/api/Login/refresh-jwt?refreshToken=${tokenStore.currentRefreshToken}`
    );
    if (response.ok) {
      tokenStore.currentJwt = await response.text();
      console.log("token refreshed");
      return;
    }

    await this.#redirectToLogin(this.router, tokenStore);
  }

  async #redirectToLogin(router: Router, tokenStore: TokenStore) {
    tokenStore.currentJwt = "";
    tokenStore.currentRefreshToken = "";
    tokenStore.nextLoginRedirect = router.currentRoute.value.path;
    await router.push("/login");
  }

  public redirectToLogin() {
    return this.#redirectToLogin(this.router, useTokenStore());
  }
}

export const httpService = new HttpService(router);

type ApiErrorResponse = {
  title: string;
  detail: string;
  code: string
};

function isApiError(error: unknown): error is ApiErrorResponse {
  return !!error
    && typeof error === "object"
    && "title" in error
    && "detail" in error
    && "code" in error;
}

function appendAuthHeader(options: AppFetchOptions, tokenStore: TokenStore) {
  if (!options.headers) options.headers = {};
  options.headers.Authorization = `Bearer ${tokenStore.currentJwt}`;
}

type AppFetchOptions = Omit<RequestInit, "headers"> & { headers?: Record<string, string> }
type AppBeforeFetch = Omit<BeforeFetchContext, "options"> & { options: AppFetchOptions };

type AppFetchType = <T>(
  url: URL | string,
  options: AppFetchOptions,
  useFetchOptions?: Omit<UseFetchOptions, "beforeFetch" | "afterFetch">
) => UseFetchReturn<T> & PromiseLike<UseFetchReturn<T>>;
const internalAppFetch = createFetch({
  options: {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    beforeFetch: async function(context: AppBeforeFetch) {
      const tokenStore = useTokenStore();
      if (tokenStore.isJwtExpired()) {
        await httpService.refreshJwt();
      }
      appendAuthHeader(context.options, tokenStore);
      return context;
    } as any,
    onFetchError(response) {
      try {
        const errorStore = useErrorStore();
        const apiError: ApiErrorResponse | unknown =
          typeof response.data === "string"
            ? tryParseJson(response.data)
            : response.data;
        let errorMessage = response.error as string ?? "http error";
        if (isApiError(apiError)) {
          errorMessage = `[${apiError.code}] ${apiError.title}`;
        }
        errorStore.triggerError({
          message: errorMessage,
          origin: "http"
        });
      } catch (e) {
        console.error("error with error handling: ", e);
      }

      return response;
    }
  }

});
export const useAppFetch: AppFetchType = (url, options, useFetchOptions) => {
  return internalAppFetch(url.toString(), options ?? {}, useFetchOptions ?? {});
};

function tryParseJson(json: string): unknown | undefined {
  try {
    return JSON.parse(json);
  } catch (e) {
    console.error("error parsing json response: ", e);
  }
  return undefined;
}