﻿import { createEventHook, createGlobalState } from "@vueuse/core";

export interface AppError {
  message: string;
  origin: string;
}

export const useErrorStore = createGlobalState(() => new ErrorStore());

class ErrorStore {
  errorEvent = createEventHook<AppError>();

  triggerError(err: AppError) {
    this.errorEvent.trigger(err);
  }

  public onError = this.errorEvent.on;
}