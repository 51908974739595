﻿import { graphql } from "@/gql";
import type { LoadApprovalsSubscription } from "@/gql/graphql";
import { expenseReportService } from "@/services/expense-report-service";
import { useTokenStore } from "@/stores/token-store";
import { useSubscription } from "@vue/apollo-composable";

export const useCashApprovalStore = () => new CashActionsStore();
export type ActionLineItem = LoadApprovalsSubscription["mseagFinanceExpenseReportLineItem"][number];

class CashActionsStore {
  private tokenStore = useTokenStore();

  //language=GraphQL
  #myActionsQuery = useSubscription(
    graphql(`
        subscription loadApprovals($userId: Int!) {
            mseagFinanceExpenseReportLineItem(
                where: {
                    type: {_in: [CASH] },
                    _or: [
                        {
                            status: {_eq: PENDING},
                            expense_report: {approverUserId: {_eq: $userId}}
                        },
                        {
                            status: {_in: [PAID, DRAFT]},
                            createdByUserId: {_eq: $userId}
                        }
                    ]
                }
            ) {
                id
                createdAt
                status
                type
                amount
                currency
                purpose
                reportId
                created_by_user {
                    id
                    avatar
                    name
                }
                channel {
                    id
                    name
                }
            }
        }
    `),
    { userId: this.tokenStore.userId ?? -1 }
  );
  
  get actions(): ActionLineItem[] {
    return this.#myActionsQuery.result.value?.mseagFinanceExpenseReportLineItem ?? [];
  }

  loadMyLineItemActions() {
    if (!this.tokenStore.userId) {
      throw new Error("no user id to load reports with");
    }
    return { loading: this.#myActionsQuery.loading };
  }

  async submitCashAdvance(id: number) {
    await expenseReportService.submitCashAdvance(id);
  }
  
  async confirmPaid(id: number) {
    await expenseReportService.confirmPaid(id);
  }
}