﻿<template>
  <v-text-field
    v-model="selectedDate"
    :label="label"
    type="datetime-local"
    :hide-details="hideDetails ?? false"
    required
  />
</template>

<script setup lang="ts">
import "@vuepic/vue-datepicker/dist/main.css";
import { computed, ref } from "vue";
const props = defineProps<{
  modelValue?: Date | string,
  label: string,
  hideDetails?: boolean,
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: Date): void
}>();
const selectedDate = computed<string | undefined>({
  get: () => {
    if (!props.modelValue) return props.modelValue;
    const date = new Date(props.modelValue);
    //toISOString returns UTC, but we really need to return local time, this fixes that.
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date.toISOString().slice(0, 16);
  },
  set: (value) => {
    if (typeof value === 'string') {
      let date = new Date(value);
      console.log('date', date, ' from ', value, ' as iso ', date.toISOString());
      emit("update:modelValue", date);
    } else if (!value) {
      //do nothing
    } else {
      console.error("unknown date format", value);
    }
  }
});


</script>

<style>
 .dp_menu {
   position: fixed !important;
 }
</style>