<template>
  <template v-if="tokenStore.approverId">
    <div class="home ma-2 ma-sm-4 ma-md-10">
      <reports-list class="report-list" mine-only draft-only title="My Expense Reports" />

      <v-card class="pending-actions" title="My Pending Actions">
        <PendingActions />
      </v-card>
      <v-card class="common-tasks" title="Common Tasks">
        <v-list class="py-0">
          <v-list-item title="Record Expense" :prepend-icon="mdiPlus" to="/add-expense" />
          <v-list-item title="Request Cash" :prepend-icon="mdiPlus" to="/add-cash-advance" />
          <v-list-item title="Currency Ex" :prepend-icon="mdiPlus" to="/add-transfer" />
        </v-list>
      </v-card>
    </div>
  </template>
  <template v-else>
    <v-col>
      <v-alert
        type="error"
        text="Your user doesn't have an approver assigned, please request an admin assign you an approver"
      />
    </v-col>
  </template>
</template>

<script setup lang="ts">
import PendingActions from "@/components/Actions/PendingActions.vue";
import ReportsList from "@/components/Report/ReportsList.vue";
import { useAppTitle } from "@/composables/useAppTitle";
import { useTokenStore } from "@/stores/token-store";
import { mdiPlus } from "@mdi/js";

useAppTitle("Home");
const tokenStore = useTokenStore();
</script>
<style scoped>
.home {
  display             : grid;
  grid-template-areas : 
    "common-tasks"
    "report-list"
    "pending-actions";
  grid-gap            : 1rem;
}
@media (min-width : 600px) {
  .home {
    grid-template-columns: 1fr auto;
    grid-template-areas : 
      "report-list common-tasks"
      "pending-actions pending-actions";
  }
}
@media (min-width : 960px) {
  .home {
    grid-template-rows: 1fr auto;
    grid-template-columns: auto 1fr;
    grid-template-areas : 
      "report-list pending-actions"
      "common-tasks pending-actions";
  }
}



.report-list {
  grid-area : report-list;
}

.pending-actions {
  grid-area : pending-actions;
}

.common-tasks {
  grid-area : common-tasks;
}
</style>