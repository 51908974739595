﻿<template>
  <v-card :title="title" :loading="reportStore.reportsLoading">
    <div v-if="showFilter" class="d-flex">
      <v-text-field v-model="titleFilter" class="ma-2" hide-details label="Filter Title" />
      <v-text-field v-if="!mineOnly && $isAdmin" v-model="userFilter" class="ma-2" hide-details label="Filter User" />
    </div>
    <v-list>
      <template
        v-for="report of filteredReports"
        :key="report.id"
      >
        <v-list-item
          :to="{name :'report-details', params: {id: report.id}}"
        >
          <template #prepend>
            <v-tooltip :text="report.user.name">
              <template #activator="{props}">
                <v-avatar v-bind="props" :image="report.user?.avatar ?? undefined" />
              </template>
            </v-tooltip>
          </template>
          <v-list-item-title>
            <v-chip size="small" color="primary" class="mr-2">
              ID {{ report.id }}
            </v-chip>
            {{ report.title }}
          </v-list-item-title>
          <template #append>
            <v-chip
              v-if="report.lineItemsAggregate.aggregate?.sum"
              size="small"
              :color="getBalanceColor(report)"
            >
              <Currency :currency="report.currency" />&nbsp;
              <Number :value="report.lineItemsAggregate.aggregate?.sum.amount ?? 0" />
            </v-chip>
            <StatusChip :prepend-icon="getIcon(report)" :progress="getProgress(report)">
              {{ report.status }}
            </StatusChip>
          </template>
        </v-list-item>
        <v-divider />
      </template>
    </v-list>
    <h4 v-if="filteredReports.length === 0 && !reportStore.reportsLoading" class="ma-4">
      No Reports
    </h4>
    <h3 v-if="reportStore.reportsLoading" class="ma-4">
      Loading...
    </h3>
  </v-card>
</template>

<script setup lang="ts">
import Currency from "@/components/Currency.vue";
import Number from "@/components/Number.vue";
import StatusChip from "@/components/StatusChip.vue";
import { expenseReportService } from "@/services/expense-report-service";
import { type Report, useReportStore } from "@/stores/report-store";
import { computed, ref } from "vue";

const props = defineProps<{ mineOnly?: boolean, draftOnly?: boolean, title?: string, showFilter?: boolean }>();
const titleFilter = ref("");
const userFilter = ref("");
const reportStore = useReportStore();
const reports = computed(() => {
    if (props.mineOnly) {
      if (props.draftOnly) return reportStore.myDraftReports;
      return reportStore.myReports;
    } else {
      if (props.draftOnly) throw new Error("combo not supported, can not use mineOnly false and draftOnly true");
      return reportStore.reports;
    }
  }
);
const filteredReports = computed(() => {
    if (!props.showFilter || (!titleFilter.value && !userFilter.value)) return reports.value;
    const titleText = titleFilter.value.toLowerCase();
    const userText = userFilter.value.toLowerCase();
    return reports.value.filter(r => {
      const passTitleFilter = titleText ? r.title.toLowerCase().includes(titleText) : true;
      const passUserFilter = userText ? r.user.name.toLowerCase().includes(userText) : true;
      return passTitleFilter && passUserFilter;
    });
  }
);

function getIcon(report: Report) {
  return expenseReportService.getIcon(report);
}

function getProgress(report: Report) {
  return expenseReportService.calculateProgress(report);
}

function  getBalanceColor(report: Report) {
  let amount = report.lineItemsAggregate.aggregate?.sum?.amount ?? 0;
  if (amount > 0) {
    return "green";
  } else if (amount < 0) {
    return "red";
  }
  return undefined;
}
</script>

<style scoped>

</style>