﻿import { apolloClient } from "@/apollo-client";
import { useErrorStore } from "@/stores/error-store";
import { createGlobalState, useStorage } from "@vueuse/core";
import { useJwt, type UseJwtOptions } from "@vueuse/integrations/useJwt";
import type { JwtPayload } from "jwt-decode";
import { computed, watch } from "vue";
import * as Sentry from "@sentry/vue";

interface MseagJwtPayload extends JwtPayload {
  email?: string;
  roles?: string[];
  approver?: string;
  receivable?: string;
}

export const useTokenStore = createGlobalState(() => new TokenStore());
export class TokenStore {
  private _nextLoginRedirect = '';
  get nextLoginRedirect(): string {
    return this._nextLoginRedirect;
  }

  set nextLoginRedirect(value: string) {
    this._nextLoginRedirect = value === '/login' ? '' : value;
  }
  constructor() {
    watch(() => this.currentJwt, () => {
      void apolloClient.clearStore();
      if (this.isLoggedIn && !this.isJwtExpired()) {
        Sentry.setUser({
          id: this.userId?.toString(),
          email: this.userEmail,
        });
      } else {
        Sentry.setUser(null);
      }
    });
  }

  private errorStore = useErrorStore();
  private jwtOptions: UseJwtOptions<MseagJwtPayload> = {
    onError: (error) => {
      this.errorStore.triggerError({
        message: (error as object)?.toString() ?? "unknown jwt decoding error",
        origin: "jwt decode",
      });
    },
    fallbackValue: {},
  };
  #currentJwt = useStorage<string>("jwt", "");

  get currentJwt() {
    return this.#currentJwt.value;
  }

  set currentJwt(jwt: string) {
    this.#currentJwt.value = jwt;
  }

  #currentRefreshToken = useStorage<string | undefined>("refresh-token", "");

  get currentRefreshToken() {
    return this.#currentRefreshToken.value;
  }

  set currentRefreshToken(token) {
    this.#currentRefreshToken.value = token;
  }

  private jwtDecoded = computed(() =>
    this.currentJwt
      ? (useJwt(this.currentJwt, this.jwtOptions).payload
          .value as MseagJwtPayload)
      : {}
  );

  private computedJwtIntValue(key: keyof typeof this.jwtDecoded.value) {
    return computed(() => {
      const value = this.jwtDecoded.value[key];
      if (typeof value === "undefined") return undefined;
      if (Array.isArray(value)) throw new Error("array not supported");
      if (typeof value === "number") {
        return isNaN(value) ? undefined : value;
      }
      const valueInt = parseInt(value, 10);
      return isNaN(valueInt) ? undefined : valueInt;
    });
  }

  #userId = this.computedJwtIntValue("sub");
  get userId() {
    return this.#userId.value;
  }

  get safeUserId(): number {
    if (!this.#userId.value) throw new Error("no user id");
    return this.#userId.value;
  }

  #approverId = this.computedJwtIntValue("approver");
  get approverId() {
    return this.#approverId.value;
  }
  #receivableAccountId = this.computedJwtIntValue("receivable");
  get receivableAccountId() {
    return this.#receivableAccountId.value;
  }
  

  get userEmail() {
    return this.jwtDecoded.value.email;
  }

  get userRoles() {
    return this.jwtDecoded.value.roles ?? [];
  }
  
  get isLoggedIn() {
    return this.currentJwt !== '' && this.userId !== undefined;
  }
  
  get isAdmin() {
    return this.userRoles.includes('admin');
  }

  isJwtExpired() {
    if (!this.jwtDecoded.value || !this.jwtDecoded.value.exp) {
      return true;
    }
    const msInSeconds = 1000;
    const nowEpocSeconds = new Date().getTime() / msInSeconds;
    return nowEpocSeconds > this.jwtDecoded.value.exp;
  }
}
