﻿<template>
  <ApprovalDialog title="Approve" :report-id="approval.reportId" @confirm="(done) => approveCash(done)">
    <p>Would you like to approve this {{ $expenseTypeName(approval.type) }}?</p>
    <p>
      Purpose: {{ approval.purpose }}
    </p>
    <p>
      Amount:
      <Currency :currency="approval.currency" />
      <Number :value="approval.amount" />
    </p>
    <v-textarea v-model="note" label="Note" auto-grow rows="2" />
  </ApprovalDialog>
</template>

<script setup lang="ts">
import ApprovalDialog from "@/components/Actions/ApprovalDialog.vue";
import Currency from "@/components/Currency.vue";
import Number from "@/components/Number.vue";
import { expenseReportService } from "@/services/expense-report-service";
import type { ActionLineItem } from "@/stores/cash-actions-store";
import type { LineItem } from "@/stores/report-detail-store";
import { ref } from "vue";

const props = defineProps<{ approval: ActionLineItem | LineItem }>();
const note = ref('');

async function approveCash(done: () => void) {
  await expenseReportService.approveCashAdvance(props.approval.id, { note: note.value });
  done();
  note.value = '';
}
</script>

<style scoped>

</style>