﻿<template>
  <v-dialog v-model="shown" activator="parent" max-width="20rem" persistent>
    <v-card>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" variant="tonal" :loading="loading" @click="confirm()">
          {{ title }}
        </v-btn>
        <v-btn :disabled="loading" @click="cancel">
          Cancel
        </v-btn>
        <v-btn v-if="router.currentRoute.value.fullPath !== reportPath" :to="reportPath" color="secondary">
          View Report
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { type DoneFunc, useDialogHelper } from "@/composables/useDialogHelper";
import { computed } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps<{
  reportId: number,
  title: string
}>();
const reportPath = computed(() => {
  return `/report/${props.reportId}`;
});
const emit = defineEmits<{
  (e: "confirm", done: DoneFunc): void;
  (e: "cancel"): void;
}>();
const { shown, confirm, loading, cancel } = useDialogHelper(
  s => emit("confirm", s),
  () => emit("cancel")
);
</script>

<style scoped>

</style>