﻿import { graphql } from "@/gql";
import type { ListUsersQuery } from "@/gql/graphql";
import { useAppFetch } from "@/services/http-service";
import { useLazyQuery, useMutation } from "@vue/apollo-composable";
import { until } from "@vueuse/core";

export type MseagUser = ListUsersQuery["mseagFinanceUsers"][number];
export const useUserStore = (() => new UserStore());

class UserStore {
  get users(): ListUsersQuery["mseagFinanceUsers"] {
    this.#usersQuery.load();
    return this.#usersQuery.result.value?.mseagFinanceUsers ?? [];
  }

  get usersLoading(): boolean {
    return this.#usersQuery.loading.value;
  }

  // language=GraphQL
  #usersQuery = useLazyQuery(graphql(`
      query listUsers {
          mseagFinanceUsers(orderBy: {name: ASC}) {
              id
              email
              role
              name
              avatar
              enabled
              defaultApproverSince
              receivableAccountId
              approver {
                  id
                  email
              }
              user_accounts {
                  id
                  account {
                      id
                      code
                      description
                      cost_center {
                          id
                          code
                          description
                      }
                  }
              }
          }
      }
  `));

  listUsers() {
    this.#usersQuery.load();
  }

  async refetchUsers() {
    //this checks if the query has been loaded or not yet
    if (this.#usersQuery.forceDisabled.value) {
      this.#usersQuery.load(undefined, undefined, { fetchPolicy: "network-only" });
      await until(this.#usersQuery.loading).toBe(false);
      return;
    }
    await this.#usersQuery.refetch();
  }

  // language=GraphQL
  private updateUserMutation = useMutation(
    graphql(`
        mutation updateUser(
            $userId: Int!, 
            $role: String!, 
            $approverId: Int, 
            $enabled: Boolean!, 
            $receivableAccountId: Int,
            $email: String!,
            $name: String!
        ) {
            updateMseagFinanceUsersByPk(
                pk_columns: { id: $userId }
                _set: { 
                    role: $role, 
                    approverUserId: $approverId, 
                    enabled: $enabled, 
                    receivableAccountId: $receivableAccountId,
                    email: $email,
                    name: $name
                }
            ) {
                id
                name
                email
                role
                enabled
                receivableAccountId
                approver {
                    id
                }
            }
        }
    `)
  );

  public async updateUser(user: MseagUser, originalUser: MseagUser) {
    await Promise.all([
      this.updateUserMutation.mutate({
        userId: user.id,
        approverId: user.approver?.id,
        role: user.role,
        enabled: user.enabled,
        receivableAccountId: user.receivableAccountId,
        email: user.email,
        name: user.name
      }),
      this.updateUserReportApprovers(user, originalUser)
    ]);
  }

  private async updateUserReportApprovers(user: MseagUser, originalUser: MseagUser) {
    if (user.approver?.id === originalUser.approver?.id
      || originalUser.approver?.id === undefined
      || user.approver?.id === undefined) return;
    await useAppFetch(
      `api/User/updateUserReportsApprover?userId=${user.id}&approverId=${user.approver.id}`,
      { method: "POST" }
    );
  }


  // language=GraphQL
  #updateDefaultApprover = useMutation(
    graphql(`
        mutation updateDefaultApprover($userId: Int!, $approverSince: timestamptz) {
            updateMseagFinanceUsersByPk(
                pk_columns: { id: $userId }
                _set: { defaultApproverSince: $approverSince}
            ) {
                id
                defaultApproverSince
            }
        }
    `)
  );

  async setDefaultApprover(userId: number, isDefault: boolean) {
    await this.#updateDefaultApprover.mutate({ userId: userId, approverSince: isDefault ? new Date() : null });
  }

  // language=GraphQL
  #addUserMutation = useMutation(
    graphql(`
        mutation addUser($user: MseagFinanceUsersInsertInput!) {
            insertMseagFinanceUsersOne(object: $user) {
                id
                role
                enabled
                receivableAccountId
                approver {
                    id
                }
            }
        }
    `)
  );

  async addUser(name: string, email: string, approverId: number) {
    await this.#addUserMutation.mutate({ user: { name, email, approverUserId: approverId } });
    await this.refetchUsers();
  }
}

