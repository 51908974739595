﻿<template>
  <!--  documentation https://vue3datepicker.com/installation/-->
  <Datepicker
    ref="datePicker"
    v-model="selectedDate"
    :format="formatDate"
    :month-picker="true"
    :teleport="true"
    :clearable="false"
    auto-apply
  >
    <template #dp-input="{value}">
      <v-text-field
        :label="label"
        :model-value="value"
        :hide-details="hideDetails ?? false"
        :append-inner-icon="mdiCalendar"
        @click:control.stop="() => openMenu()"
        @click:append-inner.stop="() => openMenu()"
      />
    </template>
  </Datepicker>
</template>

<script setup lang="ts">
import { mdiCalendar } from "@mdi/js";
import Datepicker, { type DatePickerInstance } from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { computed, ref } from "vue";

const props = defineProps<{
  modelValue?: Date | string,
  label: string,
  hideDetails?: boolean,
  dateFormat?: ConstructorParameters<typeof Intl.DateTimeFormat>[1]
}>();
const emit = defineEmits<{
  (e: "update:modelValue", value: Date): void
}>();
const datePicker = ref<DatePickerInstance>(null);
const selectedDate = computed<any>({
  get: () => {
    if (!props.modelValue) return props.modelValue;
    const date = new Date(props.modelValue);
    return { month: date.getMonth(), year: date.getFullYear() };
  },
  set: (value) => {
    if (value instanceof Date) {
      emit("update:modelValue", value);
    } else if (!value) {
      //do nothing
    } else if ("month" in value && "year" in value) {
      emit("update:modelValue", new Date(value.year, value.month, 1));
    } else {
      console.error("unknown date format", value);
    }
  }
});
const dateFormat = computed(() => {
  return new Intl.DateTimeFormat(undefined, props.dateFormat ?? { month: 'long', year: 'numeric' });
});

function openMenu() {
  datePicker.value?.openMenu();
}

function formatDate(date: Date) {
  return dateFormat.value.format(date);
}
</script>

<style>
 .dp_menu {
   position: fixed !important;
 }
</style>