﻿<template>
  <div class="account-select span-two">
    <v-autocomplete
      v-model="selectedCostCenter"
      :loading="subscription.loading.value"
      label="Cost Center"
      :items="costCenters"
      return-object
      item-value="costCenterId"
      :menu-props="{location: 'bottom center', origin: 'top center', maxHeight: '200px'}"
      :rules="[$required('Cost Center')]"
    />
    <v-autocomplete
      v-model="selectedAccount"
      :loading="subscription.loading.value"
      label="Account"
      :items="costAccounts"
      return-object
      :menu-props="{location: 'bottom center', origin: 'top center', maxHeight: '200px'}"
      :rules="[$required('Account')]"
    />
  </div>
</template>

<script setup lang="ts">
import { type CostAccount, useCostCenterStore } from "@/stores/cost-center-store";
import { useTokenStore } from "@/stores/token-store";
import { until } from "@vueuse/core";
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps<{ accountId?: number | null, userId?: number }>();
const emit = defineEmits<{
  (e: "update:accountId", accountId: number | null | undefined): void
}>();

onMounted(async () => {
  if (props.accountId) {
    await until(() => costCenters.value.length).toMatch(length => length > 0);
    selectedAccount.value = costAccounts.value.find(ac => ac.accountId == props.accountId);
  }
});

interface CostCenterItem {
  costCenterId: number;
  accounts: CostAccount[];
  title: string;
}

interface CostAccountItem {
  accountId: number;
  costId: number;
  title: string;
}
const tokenStore = useTokenStore();
const costCenterStore = useCostCenterStore();
const selectedCostCenterId = ref<number | undefined>(undefined);
const selectedCostCenter = computed({
  get: () => costCenters.value.find(cs => selectedCostCenterId.value === cs.costCenterId),
  set: val => selectedCostCenterId.value = val?.costCenterId
});

const subscription = costCenterStore.userCostCenters(()=> props.userId ?? tokenStore.safeUserId);

const costCenters = computed<CostCenterItem[]>(() => {
  return subscription.costCenters.value.map(costCenter => ({
    costCenterId: costCenter.id,
    accounts: costCenter.accounts,
    title: `${costCenter.code}: ${costCenter.description}`
  }));
});
const costAccounts = computed<CostAccountItem[]>(() => {
  if (selectedCostCenter.value) {
    return toAccountItems(selectedCostCenter.value.accounts);
  }
  return subscription.costCenters.value.flatMap(costCenter => toAccountItems(costCenter.accounts));
});

function toAccountItems(accounts: CostAccount[]) {
  return accounts.map(account => ({
    accountId: account.id,
    costId: account.costCenterId,
    title: `${account.code}: ${account.description}`
  }));
}

const selectedAccount = computed({
  get: () => costAccounts.value.find(account => props.accountId === account.accountId),
  set: val => emit('update:accountId', val?.accountId)
});

watch(selectedAccount, () => {
  if (!selectedAccount.value) return;
  selectedCostCenter.value = costCenters.value.find(cc => cc.costCenterId == selectedAccount.value!.costId);
  emit("update:accountId", selectedAccount.value.accountId);
});
</script>
<style>
.account-select .v-autocomplete__selection-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>