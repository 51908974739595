﻿<template>
  <v-dialog v-model="shown" activator="parent" max-width="20rem" persistent>
    <v-card>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" variant="tonal" :loading="loading" @click="confirm()">
          {{ props.title }}
        </v-btn>
        <v-btn :disabled="loading" @click="cancel">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { type DoneFunc, useDialogHelper } from "@/composables/useDialogHelper";

const props = defineProps<{ title: string }>();
const emit = defineEmits<{
  (e: "confirm", done: DoneFunc): void;
  (e: "cancel"): void;
}>();
const { shown, confirm, loading, cancel } = useDialogHelper(
  s => emit("confirm", s),
  () => emit("cancel")
);
</script>

<style scoped>

</style>