﻿<template>
  <template v-if="cashPaymentStore.pendingPayments.length > 0">
    <h5 class="ml-2">
      Pending Payments
    </h5>
    <v-divider />
    <v-list>
      <v-list-item
        v-for="payment of cashPaymentStore.pendingPayments"
        :key="payment.id"
        :prepend-icon="mdiFileQuestion"
        class="show-child-on-hover"
      >
        <v-list-item-title>
          <LineItemId :id="payment.id" size="small" />
          <span>{{ payment.purpose }}</span>
          <v-chip :prepend-avatar="payment.created_by_user.avatar ?? undefined" class="mx-2">
            {{ payment.created_by_user.name }}
          </v-chip>

          <v-chip :prepend-icon="mdiBankTransfer">
            {{ payment.channel?.name ?? "Error: No Payment Channel" }}
            <template v-if="payment.channel?.expenseReportLineItemsAggregate.aggregate?.count === 0">
              <v-icon :icon="mdiExclamation" color="info" />
              <v-tooltip activator="parent">
                First time payment channel has been used
              </v-tooltip>
            </template>
          </v-chip>
        </v-list-item-title>
        <v-list-item-subtitle>
          <Currency :currency="payment.currency" /> <Number :value="payment.amount" />
        </v-list-item-subtitle>
        <template #append>
          <v-btn variant="tonal" color="green" icon class="show-on-hover">
            <v-icon :icon="mdiCheck" color="green" />
            <CashPaidDialog :on-paid="date => payCashAdvance(payment, date)" :payment="payment" />
          </v-btn>
        </template>
      </v-list-item>
    </v-list>
  </template>
</template>

<script setup lang="ts">
import CashPaidDialog from "@/components/Actions/CashPaidDialog.vue";
import Currency from "@/components/Currency.vue";
import LineItemId from "@/components/LineItems/LineItemId.vue";
import Number from "@/components/Number.vue";
import { type PendingPayment, useCashPaymentStore } from "@/stores/cash-payment-store";
import { mdiBankTransfer, mdiCheck, mdiExclamation, mdiFileQuestion } from "@mdi/js";

const cashPaymentStore = useCashPaymentStore();

async function payCashAdvance(payment: PendingPayment, datePaid: Date) {
  await cashPaymentStore.markPaid(payment.id, datePaid);
}
</script>

<style scoped>

</style>