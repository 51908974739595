﻿<template>
  <h5 class="ml-2">
    Pending Payment Confirmations
  </h5>
  <v-divider />
  <v-list>
    <v-list-item v-for="confirmation of confirmations" :key="confirmation.id" :prepend-icon="mdiFileQuestion" class="show-child-on-hover">
      <v-list-item-title>
        <LineItemId :id="confirmation.id" size="small" />
        {{ confirmation.purpose }}
        <v-chip v-if="confirmation.channel" :prepend-icon="mdiBankTransfer">
          {{ confirmation.channel.name }}
        </v-chip>
      </v-list-item-title>
      <v-list-item-subtitle>
        <Currency :currency="confirmation.currency" /> <Number :value="confirmation.amount" />
      </v-list-item-subtitle>
      <template #append>
        <v-btn variant="tonal" color="green" icon class="show-on-hover">
          <v-icon :icon="mdiCheck" color="green" />
          <ConfirmDialog title="Confirm" @confirm="(done) => confirmPaid(confirmation.id, done)">
            <p>Confirm you have recieved payment?</p>
            <p>
              Purpose: {{ confirmation.purpose }}
            </p>
            <p>
              Amount:
              <Currency :currency="confirmation.currency" /> <Number :value="confirmation.amount" />
            </p>
          </ConfirmDialog>
        </v-btn>
      </template>
    </v-list-item>
  </v-list>
</template>

<script setup lang="ts">
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Currency from "@/components/Currency.vue";
import LineItemId from "@/components/LineItems/LineItemId.vue";
import Number from "@/components/Number.vue";
import type { DoneFunc } from "@/composables/useDialogHelper";
import { type ActionLineItem, useCashApprovalStore } from "@/stores/cash-actions-store";
import { mdiBankTransfer, mdiCheck, mdiFileQuestion } from "@mdi/js";
const props = defineProps<{ confirmations: ActionLineItem[] }>();
const cashApprovalStore = useCashApprovalStore();
async function confirmPaid(id: number, done: DoneFunc) {
  await cashApprovalStore.confirmPaid(id);
  done();
}
</script>

<style scoped>

</style>