/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { tryParseInt } from "@/composables/tryParseInt";
import { useLoginStore } from "@/stores/login-store";
import { useTokenStore } from "@/stores/token-store";
import AppBaseView from "@/views/AppBaseView.vue";
import HomeView from "@/views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import Testing from "@/views/Testing/Testing.vue";
import { createRouter, createWebHistory } from "vue-router";

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: AppBaseView,
      redirect: "/home",
      children: [
        {
          path: "home",
          name: "home",
          component: HomeView
        },
        {
          path: "add-cash-advance",
          component: () => import("@/views/AddCashAdvanceView.vue"),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          props: route => ({ reportId: tryParseInt(route.query.reportId as any) })
        },
        {
          path: "add-expense",
          component: () => import("@/views/AddExpenseView.vue"),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          props: route => ({ reportId: tryParseInt(route.query.reportId as any) })
        },
        {
          path: "add-transfer",
          component: () => import("@/views/AddTransferView.vue"),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          props: route => ({ 
            originReportId: tryParseInt(route.query.originReportId as any) ,
            destinationReportId: tryParseInt(route.query.destinationReportId as any) ,
          })
        },
        {
          name: "report-details",
          path: "report/:id",
          component: () => import("@/views/ReportDetailsView.vue"),
          props: route => ({ reportId: route.params.id }),
        },
        {
          name: "print-report",
          path: "report/:id/print",
          component: () => import("@/views/ReportPrintView.vue"),
          props: route => ({ reportId: route.params.id }),
        },
        {
          name: "report-wizard",
          path: "report-wizard/:reportId",
          component: () => import("@/views/ApprovalWizardView.vue"),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          // props: route => ({ reportId: tryParseInt(route.query.reportId as any) })
          props: true
        },
        {
          name: "line-item-report",
          path: "line-item-report/:reportId/:lineItemId",
          component: () => import("@/views/LineItemReportView.vue"),
          props: true
        },
        {
          path: "/channels",
          component: () => import("@/views/ChannelsView.vue")
        },
        {
          path: "/costCenters",
          component: () => import("@/views/CostCentersView.vue"),
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          props: route => ({ userId: tryParseInt(route.query.userId as any) })
        },
        { path: "users", name: "users", component: () => import("@/views/UsersView.vue") },
        {
          path: "reports",
          name: "reports",
          component: () => import("@/views/ReportsView.vue")
        },
        {
          path: 'export',
          component: () => import("@/views/ExportView.vue")
        },
        {
          path: "/login",
          name: "login",
          component: LoginView
        },
        {
          path: "/testing",
          component: Testing
        }
      ]
    }
  ]
});
