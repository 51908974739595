﻿<template>
  <div class="testing-grid ma-10">
    <v-btn>
      Confirm something?
      <ConfirmDialog title="Confirm" @confirm="onConfirm">
        Hello
      </ConfirmDialog>
    </v-btn>
    <v-btn>
      Delete something?
      <DeleteDialog title="Confirm" @delete="onConfirm">
        Hello
      </DeleteDialog>
    </v-btn>
    <v-btn @click="httpService.redirectToLogin()">
      Redirect to login
    </v-btn>

    <v-card title="Date Picker">
      <v-card-text>
        <MyDatePicker v-model="date" label="My Date" />
        <p class="mt-3">
          value: {{ date }}
        </p>
        <p>
          type: {{ dateType }}
        </p>
        <v-btn>
          In Dialog
          <v-dialog activator="parent">
            <v-card title="Update date">
              <v-card-text>
                <MyDatePicker v-model="dateDialog" label="My Date" />
                <p class="mt-3">
                  value: {{ dateDialog }}
                </p>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-btn>
      </v-card-text>
    </v-card>

    <v-card title="Month Picker">
      <v-card-text>
        <MyMonthPicker v-model="month" label="My Month" />
        <p class="mt-3">
          value: {{ month }}
        </p>
      </v-card-text>
    </v-card>
    
    <v-card title="Number formatting">
      <v-card-text>
        <Number :value="123456" />
      </v-card-text>
    </v-card>
    
    <v-card title="File" min-width="300px">
      <v-card-text>
        <v-file-input label="File Selector" @update:modelValue="logFile" />
      </v-card-text>
    </v-card>
    
    <v-card title="Loading card" :loading="loading" min-width="300px">
      <v-card-text>
        <v-btn @click="loading = !loading">
          Toggle Card loading
        </v-btn>
        <div class="my-3">
          <v-progress-circular indeterminate />
        </div>
        <div class="my-3">
          <v-progress-linear indeterminate />
        </div>
      </v-card-text>
    </v-card>
    <v-card title="Account Select" min-width="500px">
      <v-card-text>
        <AccountSelect v-model:account-id="accountId" />
        <div>Account: {{ accountId }}</div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import AccountSelect from "@/components/AccountSelect.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import DeleteDialog from "@/components/DeleteDialog.vue";
import MyDatePicker from "@/components/MyDatePicker.vue";
import MyMonthPicker from "@/components/MyMonthPicker.vue";
import Number from "@/components/Number.vue";
import { httpService } from "@/services/http-service";
import { computed, ref } from "vue";

const month = ref(new Date());
const date = ref(new Date());
const dateType = computed(() => {
  return (date.value instanceof Date) ? "Date" : typeof date.value;
});
const dateDialog = ref(new Date());

function onConfirm(done: () => void) {
  setTimeout(() => done(), 1000);
}

function logFile(files: File[]) {
  console.log('file', files);
}
const loading = ref(true);

const accountId = ref<number|undefined>(undefined);
</script>

<style scoped>
.testing-grid {
  display               : grid;
  grid-template-columns : 1fr;
  justify-items         : start;
  grid-gap              : 2rem;
}
</style>