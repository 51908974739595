﻿import { graphql } from "@/gql";
import type { LoadReportApprovalsSubscription } from "@/gql/graphql";
import { useTokenStore } from "@/stores/token-store";
import { useSubscription } from "@vue/apollo-composable";

export type PendingReport = NonNullable<LoadReportApprovalsSubscription["mseagFinanceExpenseReports"]>[number];
export const usePendingReportStore = () => new PendingReportStore();

class PendingReportStore {
  tokenStore = useTokenStore();
  //language=GraphQL
  #myPendingReportActions = useSubscription(
    graphql(`
        subscription loadReportApprovals($userId: Int!) {
            mseagFinanceExpenseReports(
                where: {
                    status: {_eq: PENDING}
                    approverUserId: {_eq: $userId}
                }
            ) {
                id
                createdAt
                title
                status
                user {
                    id
                    name
                    avatar
                }
            }
        }
    `),
    { userId: this.tokenStore.userId ?? -1 }
  );

  get pendingReports(): PendingReport[] {
    return this.#myPendingReportActions.result.value?.mseagFinanceExpenseReports ?? [];
  }
}